"use strict";
import Media, { IMedia } from "./media.model";
/**
 * Core(s)
 */
import { Model, IModel, ModelError, TPayload } from "./model";

export enum EMarketingAds {
   shopHome = "shopHomeAds",
   construction = "constructionAds",
   pointPolicy = "pointPolicyAds",

   flashSales = "flashSalesAds",
   bestSeller = "bestSellerAds",
   newArrival = "newArrivalAds",
   feedHome = "feedHomeAds",
   blogHome = "blogHomeAds",
   libraryHome = "libraryHomeAds",
   designLibrary = "designLibraryAds",
   actualWork = "actualWorkAds",
   calculator = "calculatorAds",
   interiorFirm = "interiorFirmAds",
   quytrinhketnoi = "quytrinhketnoiAds",
}

export enum EMarketingAdsType {
   homepage = "homepage",
   blog = "blog",
}
export interface IMarketingAds extends IModel {
   ["id"]: string;
   ["info"]: IMedia;
   ["meta"]: {
      ["description"]: string;
      ["title"]: string;
   };
   ["options"]: {
      ["status"]: string;
   };
   ["section"]: EMarketingAds;
   ["updatedAt"]: string;
   ["onPage"]: EMarketingAdsType;
}

export class MarketingAds extends Model implements IMarketingAds {
   public ["id"]: string;
   public ["info"]: Media;
   public ["meta"]: {
      ["description"]: string;
      ["title"]: string;
   };
   public ["options"]: {
      ["status"]: string;
   };
   public ["section"]: EMarketingAds;
   public ["onPage"]: EMarketingAdsType;

   constructor(data: IMarketingAds) {
      super();

      Object.assign(this, data);
      if (data?.info) {
         this.info = new Media(data.info);
      }
   }

   public static async getPopup(type?: EMarketingAdsType) {
      try {
         const response = await this.fetch({
            method: "GET",
            endPoint: "marketing-ads",
            headers: {
               ...(type && { alias: type }),
            },
         });

         if (response instanceof ModelError) return response;

         const payload = (await response) as TPayload<{
            animationText: IMarketingAds;
            floating: IMarketingAds;
            popup: IMarketingAds;
         }>;

         return {
            animationText: payload.data.animationText ? new MarketingAds(payload.data.animationText) : undefined,
            floating: payload.data.floating ? new MarketingAds(payload.data.floating) : undefined,
            popup: payload.data.popup ? new MarketingAds(payload.data.popup) : undefined,
         };
      } catch (error: any) {
         // console.log(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling.",
                  },
               ],
            },
         });
      }
   }
}

export default MarketingAds;
